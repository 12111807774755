.header {
  width: 240px;
  height: auto;
  padding-right: 32px;
  padding-bottom: 32px;
  border-radius: 0 0 32px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.header__logo {
  display: block;
}
.header__logo:hover {
  opacity: 0.8;
}
.header__logo img {
  width: 100%;
}
.header__descSp {
  display: none;
}
.header__desc {
  padding-top: 8px;
}
.header__desc p,
.header__descSp p {
  font-size: 12px;
  font-weight: bold;
  color: #888;
  display: inline;
}
.header__desc p::after,
.header__descSp p::after {
  content: '|';
  padding-left: 4px;
  margin-right: 4px;
  display: inline;
}
.header__desc h1,
.header__descSp h1 {
  font-size: 12px;
  font-weight: normal;
  color: #888;
  display: inline;
}
.header__ic1,
.header__ic2 {
  width: 32px;
  height: 32px;
  position: absolute;
}
.header__ic1 {
  top: 0;
  right: -32px;
}
.header__ic2 {
  bottom: -32px;
  left: 0;
}
@media screen and (max-width: 768px) {
  .header {
    width: 144px;
    padding-right: 16px;
    padding-bottom: 16px;
    border-radius: 0 0 16px 0;
  }
  .header__desc {
    display: none;
  }
  .header__descSp {
    width: 100%;
    padding: 8px 16px 0 16px;
    display: block;
  }
  .header__ic1,
  .header__ic2 {
    width: 16px;
    height: 16px;
  }
  .header__ic1 {
    top: 0;
    right: -16px;
  }
  .header__ic2 {
    bottom: -16px;
    left: 0;
  }
}

.mainvisual {
  width: 100%;
  height: 100vh;
  padding: 32px;
}
.mainvisual__inner {
  width: 100%;
  height: 100%;
  position: relative;
}
.mainvisual__pic {
  width: 100%;
  height: 100%;
  border-radius: 32px;
  object-fit: cover;
  top: 0;
  left: 0;
}
.mainvisual__desc {
  width: 800px;
  height: auto;
  padding: 32px 0 32px 32px;
  border-radius: 32px 0 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
}
.mainvisual__desc .title {
  font-size: 36px;
  letter-spacing: 0.16em;
  word-break: break-all;
  font-weight: bold;
}
.mainvisual__desc p {
  font-size: 14px;
  letter-spacing: 0.1em;
  word-break: break-all;
  white-space: pre-line;
}
.mainvisual__desc__ic1,
.mainvisual__desc__ic2 {
  width: 32px;
  height: 32px;
  position: absolute;
}
.mainvisual__desc__ic1 {
  top: -32px;
  right: 0;
  transform: rotate(180deg);
}
.mainvisual__desc__ic2 {
  bottom: 0;
  left: -32px;
  transform: rotate(180deg);
}
.item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.item__li {
  margin-bottom: 32px;
}
.item__li:hover {
  opacity: 0.9;
}
.item__li:nth-child(5n-4),
.item__li:nth-child(5n-3) {
  width: calc((100% - 32px) / 2);
}
.item__li:nth-child(5n-2),
.item__li:nth-child(5n-1),
.item__li:nth-child(5n) {
  width: calc((100% - 64px) / 3);
}
.item__li:nth-child(5n-4),
.item__li:nth-child(5n-2),
.item__li:nth-child(5n-1) {
  margin-right: 32px;
}
.item__li:nth-child(5n-2) .item__li__sub__tag,
.item__li:nth-child(5n-1) .item__li__sub__tag,
.item__li:nth-child(5n) .item__li__sub__tag {
  width: 100%;
  margin-bottom: 40px;
}
.c-photo__main {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translate3d(0px, 0, 0);
  overflow: hidden;
  border-radius: 32px;
}
.c-photo__main__list {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 1;
}
.c-photo__main__list__item {
  width: 100%;
  height: 100%;
  background: #eee;
  flex-shrink: 0;
  transition: transform 500ms;
}
.c-photo__main__list__item img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.pictures__all__photo__btn {
  width: 56px;
  height: 56px;
  position: absolute;
  top: calc(50% - 28px);
}
.pictures__all__photo__btn:hover {
  opacity: 1;
}
.pictures__all__photo__btn > span:first-child {
  opacity: 0.9;
  background: #fffaeb !important;
  background: var(--theme-background-color);
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.pictures__all__photo__btn > span:last-child {
  width: 16px;
  height: 16px;
  border-top-width: 2px;
  border-left-width: 2px;
  border-top-style: solid;
  border-left-style: solid;
  position: absolute;
  top: 20px;
  left: 22px;
  transform: rotate(-45deg);
  display: block;
}
.pictures__all__photo__btn.prev {
  left: 32px;
}
.pictures__all__photo__btn.next {
  right: 32px;
  transform: scale(-1, 1);
}
@media screen and (max-width: 768px) {
  .item__li:nth-child(5n-4),
  .item__li:nth-child(5n-3),
  .item__li:nth-child(5n-2),
  .item__li:nth-child(5n-1),
  .item__li:nth-child(5n) {
    width: 100%;
  }
  .item__li:nth-child(5n-4),
  .item__li:nth-child(5n-2),
  .item__li:nth-child(5n-1) {
    margin-right: 0;
  }
  .mainvisual {
    height: 432px;
    padding: 16px;
  }
  .mainvisual__pic {
    border-radius: 16px;
  }
  .mainvisual__desc {
    width: 240px;
    padding: 8px 0 0 16px;
    border-radius: 16px 0 0 0;
    margin-bottom: -16px;
  }
  .mainvisual__desc h2 {
    font-size: 16px;
  }
  .mainvisual__desc p {
    font-size: 12px;
  }
  .mainvisual__desc__ic1,
  .mainvisual__desc__ic2 {
    width: 16px;
    height: 16px;
  }
  .mainvisual__desc__ic1 {
    top: -16px;
    right: 0;
  }
  .mainvisual__desc__ic2 {
    bottom: 16px;
    left: -16px;
  }
  .c-photo__main {
    border-radius: 16px;
  }
  .pictures__all__photo__btn {
    width: 32px;
    height: 32px;
    top: calc(50% - 16px);
  }
  .pictures__all__photo__btn:hover {
    opacity: 1;
  }
  .pictures__all__photo__btn > span:first-child {
    opacity: 0.9;
    background: #fffaeb !important;
    background: var(--theme-background-color);
  }
  .pictures__all__photo__btn > span:last-child {
    width: 8px;
    height: 8px;
    top: 11px;
    left: 13px;
  }
  .pictures__all__photo__btn.prev {
    left: 8px;
  }
  .pictures__all__photo__btn.next {
    right: 8px;
  }
}
